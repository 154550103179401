import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import './index.css';
import { ReactComponent as BgIcon } from '../../../assets/image/button-bg.svg';
import classNames from 'classnames';

type CommonButtonProps = {
  handleClick: any;
  name?: string;
  outline?: boolean;
  thick?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  leftIcon?: React.ReactNode;
  children?: React.ReactNode;
};
export function CommonButton({
  leftIcon,
  handleClick,
  name,
  outline,
  className,
  disabled,
  loading,
  thick,
  children,
}: CommonButtonProps) {
  return (
    <Box
      className={classNames(
        'JoinButtonBox',
        thick && 'thickButton',
        outline && 'outlinedButton',
        disabled && 'disabledButton',
        loading && 'disabledButton',
        className,
      )}
      onClick={disabled || loading ? () => undefined : handleClick}
    >
      <BgIcon preserveAspectRatio="none" className="JoinButtonBoxBg" />
      <Flex lineHeight="2.5rem" justifyContent="center" alignItems="center" gap={2} zIndex={2}>
        {leftIcon}
        {name}
        {children}
        {loading && (
          <Box mt={1}>
            <Spinner />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
