import React from 'react';
import {
  Box,
  Flex,
  Image,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
} from '@chakra-ui/react';
// import styles from './index.module.scss';
// import { useWallet } from '../../store/wallet-context';
import LogoIcon from '../../assets/image/logo.png';
import NodeDragonIcon from '../../assets/image/node-dragon.svg';
import WalletButton from '../WalletButton/WalletButton';
import EthereumWalletButton from '../EthereumWalletButton/EthereumWalletButton';

import { Link, useMatches } from 'react-router-dom';
// import classNames from 'classnames';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { AiOutlineMenu } from 'react-icons/ai';
import NavMenu from './NavMenu/NavMenu';
import { evmRoutes } from '../../const/misc';

export function Header() {
  // const { isConnected, accountAddress, disconnect, connect } = useWallet();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobileView = useIsMobileView(1280);

  const matches = useMatches();

  const showEVMWalletButton = matches.some((match) => evmRoutes.includes(match.pathname));

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="absolute"
        height={['80px', null, '100px']}
        width="100%"
        overflow="hidden"
        paddingX={['20px', null, '40px']}
        zIndex={1}
      >
        <Link to="">
          <Flex alignItems="center" gap="10px">
            <Image src={LogoIcon} alt="logo" height={['48px', null, '64px']} />
            <Image
              src={NodeDragonIcon}
              alt="logo"
              height="35px"
              display={['none', null, 'block']}
            />
          </Flex>
        </Link>
        {/* {!isMobileView && <NavMenu />} */}
        {/* <Flex alignItems="center" gap="10px">
          {showEVMWalletButton ? <EthereumWalletButton /> : <WalletButton />}
          {isMobileView && (
            <Icon
              as={AiOutlineMenu}
              fontSize="32px"
              color="#09E841"
              onClick={onOpen}
              cursor="pointer"
            />
          )}
        </Flex> */}
      </Box>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent backgroundColor="rgba(0,0,0,0.5)">
          <DrawerCloseButton color="#3AD673" />
          <DrawerBody>
            <NavMenu isMobile onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
