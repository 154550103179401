import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import numeral from 'numeral';

type CountdownProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
};

const Countdown = ({ days, hours, minutes, seconds, completed }: CountdownProps) => {
  if (completed) return null;
  return (
    <Flex alignItems="center" gap={['0', null, '30px']}>
      {days > 0 && (
        <Flex flexDir="column" alignItems="center" gap="5px" padding="10px">
          <Text color="#3AD673" fontFamily="PressStart2P" fontSize="36px">
            {numeral(days).format('00')}
          </Text>
          <Text fontSize="16px">DAYS</Text>
        </Flex>
      )}
      <Flex flexDir="column" alignItems="center" gap="5px" padding="10px">
        <Text color="#3AD673" fontFamily="PressStart2P" fontSize="36px">
          {numeral(hours).format('00')}
        </Text>
        <Text fontSize="16px">HOURS</Text>
      </Flex>
      <Flex flexDir="column" alignItems="center" gap="5px" padding="10px">
        <Text color="#3AD673" fontFamily="PressStart2P" fontSize="36px">
          {numeral(minutes).format('00')}
        </Text>
        <Text fontSize="16px">MINUTES</Text>
      </Flex>
      <Flex flexDir="column" alignItems="center" gap="5px" padding="10px">
        <Text color="#3AD673" fontFamily="PressStart2P" fontSize="36px">
          {numeral(seconds).format('00')}
        </Text>
        <Text fontSize="16px">SECONDS</Text>
      </Flex>
    </Flex>
  );
};

export default Countdown;
