import React, { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TokenPocketWalletAdapter,
  TrustWalletAdapter,
  // WalletConnectWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { OKXWalletAdapter } from '../adapter/okxwalletAdapter';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
// import { clusterApiUrl } from '@solana/web3.js';

const SolanaProvider = ({ children }: { children: React.ReactNode }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = process.env.REACT_APP_NETWORK! as WalletAdapterNetwork;
  // You can also provide a custom RPC endpoint.
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const endpoint = process.env.REACT_APP_SOLANA_RPC!;

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/solana-labs/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new OKXWalletAdapter(),
      new TokenPocketWalletAdapter(),
      new TrustWalletAdapter(),

      // new WalletConnectWalletAdapter({
      //   network,
      //   options: {
      //     // relayUrl: 'wss://relay.walletconnect.com',
      //     projectId: 'e0ae6d308b9f3d5e501f1d935d87b70c',
      //     metadata: {
      //       name: 'Node Dragons',
      //       description: 'Node Dragons',
      //       url: 'https://www.nodedragons.com/',
      //       icons: ['https://www.nodedragons.com/favicon.ico']
      //     }
      //   }
      // })
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  );
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
export default SolanaProvider;
