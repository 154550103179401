import { useMemo } from "react";
import useUpdate from "./useUpdate";

const useCheckTimePassed = (targetTime: number) => {
  const {updateByTimer} = useUpdate()
  const isTimePassed = useMemo(() => {
    return Date.now() - targetTime > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateByTimer, targetTime])
  return isTimePassed;
};

export default useCheckTimePassed;