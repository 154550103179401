import React, { useContext, useMemo } from 'react';
import { useReadContract } from 'wagmi';
import { fromHex, getAddress } from 'viem';

import nameServiceABI from '../abis/NameServiceInterface';
import { merlin } from 'viem/chains';

// name service keys from the contract
export enum SingleEntryKeys {
  Airdrop = 'Airdrop',
  Drak = 'Drak',
  Multicall3 = 'Multicall3',
}

type NameServiceContextType = Record<string, Record<string, string> | null>;

const NameServiceContext = React.createContext<NameServiceContextType>({});

export const useNameService = () => useContext(NameServiceContext);

// pay attention to contract specific record type
const decodeEntries = (records: readonly Record<string, any>[] | undefined) =>
  records
    ? records.reduce(
        (accum, item) => ({
          ...accum,
          [fromHex(item.name, {
            size: 32,
            to: 'string',
          })]: item.record.addr,
        }),
        {},
      )
    : null;

const useProvideNameservice = () => {
  const { data: entries } = useReadContract({
    abi: nameServiceABI,
    address: getAddress(process.env.REACT_APP_NS_ADDRESS!),
    functionName: 'listEntries',
    chainId: merlin.id,
  });

  const decodedEntries = useMemo(() => {
    let singleEntries = null,
      multiEntries = null;
    if (entries) {
      singleEntries = decodeEntries(entries[0]);
      multiEntries = null;
    }
    // console.log('ns', { singleEntries, multiEntries });

    return { singleEntries, multiEntries };
  }, [entries]);

  return decodedEntries;
};

const NameServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const addresses = useProvideNameservice();
  return <NameServiceContext.Provider value={addresses}>{children}</NameServiceContext.Provider>;
};

export default NameServiceProvider;
