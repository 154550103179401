import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import styles from './App.module.scss';

function App() {
  // const location = useLocation();
  // const path = location.pathname;
  return (
    <div className={styles.container}>
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
