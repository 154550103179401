
const digest = '72313e432f73b0b64e505d7f124c42b72235a12b3842ef8816b5e500b7fc785a';
const classes = {"modalContent":"_modalContent_ikogv_1","closeBtn":"_closeBtn_ikogv_5","chainInfo":"_chainInfo_ikogv_11","addressContainer":"_addressContainer_ikogv_24","addressInfo":"_addressInfo_ikogv_29","detailsContent":"_detailsContent_ikogv_44","fill":"_fill_ikogv_53","estimatedGas":"_estimatedGas_ikogv_56","signBtn":"_signBtn_ikogv_61","notRemind":"_notRemind_ikogv_65"};
const css = `._modalContent_ikogv_1{color:#fff;text-align:center}._modalContent_ikogv_1 ._closeBtn_ikogv_5{cursor:pointer;position:absolute;right:16px;top:16px}._modalContent_ikogv_1 ._chainInfo_ikogv_11{align-items:center;display:flex;flex-direction:row;font-size:12px;gap:8px;justify-content:center;margin-bottom:8px}._modalContent_ikogv_1 ._chainInfo_ikogv_11 img{height:18px;width:18px}._modalContent_ikogv_1 ._addressContainer_ikogv_24{height:28px;margin-bottom:16px;margin-top:8px}._modalContent_ikogv_1 ._addressContainer_ikogv_24 ._addressInfo_ikogv_29{align-items:center;background:rgba(58,58,58,.635);border-radius:20px;display:flex;flex-direction:row;font-size:12px;gap:4px;justify-content:center;padding:4px 8px}._modalContent_ikogv_1 ._addressContainer_ikogv_24 ._addressInfo_ikogv_29 img{height:18px;width:18px}._modalContent_ikogv_1 ._detailsContent_ikogv_44{max-height:0;overflow-y:auto;transition:max-height .3s linear;width:100%}._modalContent_ikogv_1 ._detailsContent_ikogv_44::-webkit-scrollbar{display:none}._modalContent_ikogv_1 ._detailsContent_ikogv_44._fill_ikogv_53{max-height:500px}._modalContent_ikogv_1 ._estimatedGas_ikogv_56{color:hsla(0,0%,100%,.631);font-size:12px;margin-top:24px}._modalContent_ikogv_1 ._signBtn_ikogv_61{margin-top:24px;min-width:200px}._modalContent_ikogv_1 ._notRemind_ikogv_65{align-items:center;color:hsla(0,0%,100%,.631);cursor:pointer;display:inline-flex;font-size:12px;gap:4px;margin-top:16px}._modalContent_ikogv_1 ._notRemind_ikogv_65:hover{opacity:.85}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  