import { extendTheme, defineStyleConfig } from '@chakra-ui/react';
// import { mode } from '@chakra-ui/theme-tools';

// const styles = {
//   global: (props: any) => ({
//     body: {
//       color: mode('#000000', '#EEEEEE')(props),
//       bg: mode('gray.100', '#141214')(props),
//     },
//   }),
// };

const progressTheme = defineStyleConfig({
  baseStyle: {
    filledTrack: {
      bg: '#00AA44',
    },
    track: {
      bg: '#003F09',
      borderRadius: '50px',
    },
  },
});

const theme = extendTheme({
  // styles,
  components: {
    Progress: progressTheme,
  },
});

export default theme;
