import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import numeral from 'numeral';

export const truncAddress = (addr: string) => {
  if (addr && addr !== ethers.constants.AddressZero) {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
  }
  return '-';
};

export function truncateNumber(str: string, maxDecimalDigits = 4) {
  if (str.includes('.')) {
    const parts = str.split('.');
    let fracturePart = parts[1].slice(0, maxDecimalDigits);
    while (fracturePart.endsWith('0') && fracturePart.length > 1) {
      fracturePart = fracturePart.slice(0, -1);
    }
    return parts[0] + '.' + fracturePart;
  }

  return str;
}

export function stringToBytes32(input: string): string {
  return ethers.utils.formatBytes32String(input);
}

export function bytes32ToString(input: string): string {
  return ethers.utils.parseBytes32String(input);
}

export function hexify(input: string): string {
  // return ethers.utils.hexlify(ethers.utils.toUtf8Bytes(input));
  const result = ethers.utils.formatBytes32String(input);
  // console.log('hexify', input, result);
  return result;
}

export async function callWithToastError<T>(func: () => Promise<T>, toast: any): Promise<T | null> {
  try {
    return await func();
  } catch (e: unknown) {
    console.error(e);
    if (e instanceof Error) {
      toast({
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    return null;
  }
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const formatNumber = (num: string | undefined, formatString = '0,0[.]00') => {
  if (!num || Number(num) === 0 || Number(num) < 1e-6) {
    return '-';
  }
  return numeral(num).format(formatString);
};
