import React from 'react';
import { NavItemType } from '../NavMenu/NavMenu';
import { Flex, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavItem.module.scss';

type NavItemProps = NavItemType & { callback?: () => void; isMobile?: boolean };

const NavItem = ({ callback, label, href, onClick, isFuture }: NavItemProps) => {
  return (
    <Flex flexDir="column" alignItems="center" gap="5px">
      <NavLink
        to={href}
        className={({ isActive }) => classNames(styles.link, { [styles.isActive]: isActive })}
        onClick={() => {
          if (onClick) onClick();
          if (callback) callback();
        }}
      >
        {label}
      </NavLink>
      <Text
        visibility={isFuture ? 'visible' : 'hidden'}
        fontSize="10px"
        padding="5px"
        backgroundColor="rgba(0, 0, 0, 0.20)"
        color="#9DAAF6"
      >
        Coming Soon
      </Text>
    </Flex>
  );
};

export default NavItem;
