import React from 'react';
import styles from './Home.module.scss';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { CommonButton } from '../../components/UI/CommonButton';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import { ReactComponent as NodeIcon } from '../../assets/image/node.svg';
import { ReactComponent as DragonIcon } from '../../assets/image/dragon.svg';
import TwitterIcon from '../../assets/image/twitter.png';
import TeleIcon from '../../assets/image/telegram.png';
import DiscordIcon from '../../assets/image/launchpad/discord.svg';
import dragonBlue from '../../assets/image/dragon-blue.png';
import dragonRed from '../../assets/image/dragon-red.png';
// import { useGetNSAddress } from '../../hooks/useGetNSAddress';
// import { SingleEntryKeys } from '../../store/name-service-context';
// import CopyToClipboard from 'react-copy-to-clipboard';
// import copyLogo from '../../assets/image/copy.svg';
import { CommonInput } from '../../components/UI/CommonInput';
import { tokenWhitelist } from '../../const/tokenWhitelist';
import Countdown from 'react-countdown';
import { default as CustomCountdown } from '../../components/UI/Countdown/Countdown';
import { mintStartTimestamp } from '../../const/tokenLaunch';

export default function Home() {
  // const drakAddress = useGetNSAddress(SingleEntryKeys.Drak);
  // const toast = useToast();
  const [inputAddress, setInputAddress] = React.useState('');
  const [tokenWhitelisted, setTokenWhitelisted] = React.useState<boolean | null>(null);

  const handleInputAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputAddress(event.target.value);
  };

  const checkTokenWhitelistAddress = async () => {
    const isWhiteList = tokenWhitelist.filter(
      (address) => inputAddress.toLowerCase().trim() === address.toLowerCase().trim(),
    );
    if (isWhiteList.length) {
      setTokenWhitelisted(true);
    } else {
      setTokenWhitelisted(false);
    }
  };

  const gotoTwitter = () => {
    window.open('https://twitter.com/NodeDragons');
  };
  const gotoTelegram = () => {
    window.open('https://t.me/NodeDragons');
  };
  const gotoDiscord = () => {
    window.open('https://discord.gg/tFe7HrrA5W');
  };
  // const gotoWhitepaper = () => {
  //   window.open('https://blockchain-9.gitbook.io/nodedragons/');
  // };

  const switchToMint = () => {
    window.open('https://inscribenow.io/collections/02a1a6722eecc0d5');
  };
  return (
    <div className={styles.container} style={{ overflow: 'clip' }}>
      <Image src={dragonBlue} className={styles.dragonBlue} />
      <Image src={dragonRed} className={styles.dragonRed} />
      <MainContainer>
        <Flex direction="column" position="relative">
          <Box className={styles.title}>
            <NodeIcon className={styles.node} />
            <DragonIcon className={styles.dragon} />
          </Box>
          <Text fontSize={'12px'} color="white" textTransform={'uppercase'}>
            The Gaming Platform for Bitcoin Ecosystem
          </Text>
          {/* <Text fontSize={'16px'} fontFamily="fusion" color="white" paddingTop={'10px'}>
            Total Supply: 100,000,000,000
          </Text>
          <CopyToClipboard
            text={drakAddress || ''}
            onCopy={() => {
              toast({ title: 'Contract Address Copied', status: 'info', position: 'top' });
            }}
          >
            <Text
              fontSize={'16px'}
              fontFamily="fusion"
              color="white"
              paddingTop={'10px'}
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
            >
              Contract: {drakAddress} <Image src={copyLogo} width="15px" />
            </Text> 
          </CopyToClipboard>*/}

          <Box
            width="100%"
            maxWidth="400px"
            marginBottom="20px"
            mt="220px"
            display="flex"
            flexDir="column"
            alignItems="center"
            gap="10px"
          >
            <div className={styles.countdownWrapper}>
              <p>Mint starts in</p>
              <Countdown date={Number(mintStartTimestamp * 1000)} renderer={CustomCountdown} />
            </div>
            <CommonInput
              className={styles.input}
              value={inputAddress}
              onChange={handleInputAddressChange}
              placeholder="ENTER YOUR ADDRESS"
            />
            <CommonButton
              className={styles.checkButton}
              handleClick={checkTokenWhitelistAddress}
              name="Check OG"
            />
            <CommonButton className={styles.checkButton} handleClick={switchToMint} name="Mint" />
            {/* <CommonButton
              className={styles.checkButton}
              handleClick={gotoWhitepaper}
              name="Whitepaper"
            />
            <Link isExternal href="https://www.orca.so/">
              <CommonButton
                className={styles.checkButton}
                handleClick={() => null}
                name="Buy NODR"
              />
            </Link>
            <Link
              isExternal
              href="https://dexscreener.com/solana/73h3han8lwkormg7tzxdyblv7crcav5lcb8u4nfzyrrs"
            >
              <CommonButton className={styles.checkButton} handleClick={() => null} name="Chart" />
            </Link> */}
            {/* <Link
              href="https://merlinswap.org/trade/swap?tokenFrom=0xf6d226f9dc15d9bb51182815b320d3fbe324e1ba&tokenTo=0xc69a8ef7da38fb6bf035c38ea4a0a5e0a529378d&chainId=4200"
              isExternal
            >
              <CommonButton
                className={styles.checkButton}
                handleClick={() => null}
                name="Get Drak"
              />
            </Link>
            <Link href="/claim">
              <CommonButton
                className={styles.checkButton}
                handleClick={() => null}
                name="Airdrop"
              />
            </Link> */}
            {/* <Text
              fontFamily="fusion"
              display="flex"
              flexDir={'column'}
              alignItems={'center'}
              color={'#09E841'}
            >
              DragonCoin:
              <Link
                isExternal
                href="https://solscan.io/token/8Jcx4QvD9atyHx6b8G47TDtRbr5x21dXH862jnsZ2BmT"
              >
                8Jcx4QvD9atyHx6b8G47TDtRbr5x21dXH862jnsZ2BmT
              </Link>
            </Text> */}
          </Box>

          {tokenWhitelisted !== null && (
            <Text color="white" position="absolute" bottom="-30px" whiteSpace="nowrap">
              {tokenWhitelisted
                ? "Congratulations! You're eligible to participate in the OG mint"
                : 'You are not on the whitelist, sorry!'}
            </Text>
          )}
        </Flex>

        <Flex className={styles.socialWrap}>
          <CommonButton
            className={styles.socialButton}
            handleClick={gotoTwitter}
            name="TWITTER"
            leftIcon={<Image src={TwitterIcon} className={styles.buttonIcon} />}
          />
          {/* <CommonButton
            className={styles.socialButton}
            handleClick={gotoTelegram}
            name="TELEGRAM"
            leftIcon={<Image src={TeleIcon} className={styles.buttonIcon} />}
          />
          <CommonButton
            className={styles.socialButton}
            handleClick={gotoDiscord}
            name="Discord"
            leftIcon={<Image src={DiscordIcon} className={styles.buttonIcon} />}
          /> */}
        </Flex>
      </MainContainer>
    </div>
  );
}
