import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { WagmiProvider } from 'wagmi';
import { defineChain } from 'viem';

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '8d6dd729a0a6fc34d073adbe3690dc40';

// 2. Create wagmiConfig
const metadata = {
  name: 'Node Dragon',
  description: 'Node Dragon',
  url: 'https://www.nodedragons.com/', // origin must match your domain & subdomain
  icons: ['https://www.nodedragons.com/favicon.ico'],
};

const merlin = defineChain({
  id: 4200,
  name: 'Merlin Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BTC',
    symbol: 'BTC',
  },
  rpcUrls: {
    default: { http: ['https://rpc.merlinchain.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Merlin block explorer',
      url: 'https://scan.merlinchain.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0x63eA471B02C9EEd1D7E92effBB597ab356B5aB90',
    },
  },
});

const config = defaultWagmiConfig({
  chains: [merlin],
  projectId,
  metadata,
  enableWalletConnect: true,
  enableInjected: true,
  enableEIP6963: true,
  enableCoinbase: true,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
  themeMode: 'dark',
});

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}

export function Web3ModalProvider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
