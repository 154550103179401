import React from 'react';
import { Merlin } from '@particle-network/chains';
import {
  ConnectProvider,
  OKXConnector,
  UnisatConnector,
  BitgetConnector,
} from '@particle-network/btc-connectkit';

export function BTCConnectProvider({ children }: { children: React.ReactNode }) {
  return (
    <ConnectProvider
      options={{
        projectId: process.env.REACT_APP_BTC_CONNECT_PROJECT_ID!,
        clientKey: process.env.REACT_APP_BTC_CONNECT_CLIENT_KEY!,
        appId: process.env.REACT_APP_BTC_CONNECT_APP_ID!,
        aaOptions: {
          accountContracts: {
            BTC: [
              {
                chainIds: [Merlin.id], // The chain you'd like to use, Merlin in this case.
                version: '1.0.0', // Keep this as 1.0.0 for now.
              },
            ],
          },
        },
        walletOptions: {
          visible: false, // Whether or not the embedded wallet modal (for controlling the smart account) is shown.
        },
      }}
      // List of supported wallets.
      connectors={[new UnisatConnector(), new OKXConnector(), new BitgetConnector()]}
    >
      {children}
    </ConnectProvider>
  );
}
