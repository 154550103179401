import drakAirdropBannerImg from '../assets/image/tokenLaunchNew/titleBg.png';
import drakAirdropLogo from '../assets/image/tokenLaunchNew/drakLogo.png';
import DrakAirdrop from '../components/Claim/DrakAirdrop/DrakAirdrop';

export const claimList = [
  {
    title: 'DRAK Airdrop',
    description: '',
    cardBannerImg: drakAirdropBannerImg,
    cardTitleLogo: drakAirdropLogo,
    link: '/claimDetail/drakAirdrop',
    component: DrakAirdrop,
    tokenName: 'DRAK',
    tokenAmount: '10000000000',
    startTimestamp: 1710774000,
    endTimestamp: 1710946800,
    chain: 'Merlin',
  },
];
