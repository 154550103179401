import { Box, Image, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import tokenLogo from '../../../assets/image/tokenLaunchNew/drakLogo.png';
import btcLogo from '../../../assets/image/btcLogo.svg';
import { CommonButton } from '../../UI/CommonButton';
import useIsWhitelisted from '../../../hooks/useIsWhitelisted';
import {
  whitelist_huhu,
  whitelist_iusd,
  whitelist_misc,
  whitelist_pointer,
  whitelist_rat404,
  whitelist_voya,
} from '../../../const/drakAirdropWhitelist';
import { useWeb3Context } from '../../../store/web3-context';
import { formatNumber } from '../../../utils/utils';
import { formatEther, stringToHex } from 'viem';
import useAirdrop from '../../../hooks/useAirdrop';

const domain = 'drak-airdrop-1';
const startTimestamp = 1710774000;
const endTimestamp = 1710946800;

const DrakAirdrop = () => {
  const { nativeBalance, evmAccountAddress, publicClient } = useWeb3Context();
  const { getClaimedAmount, claim } = useAirdrop();
  const [loading, setLoading] = useState(false);

  const [claimedAmount, setClaimedAmount] = useState(0n);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (evmAccountAddress) {
          const claimedAmount = await getClaimedAmount(
            evmAccountAddress,
            stringToHex(domain, { size: 32 }),
          );
          setClaimedAmount(claimedAmount);
        } else {
          setClaimedAmount(0n);
        }
      } catch (error) {
        console.log('fetch claimed amount error', error);
        setClaimedAmount(0n);
      }
    };
    fetch();
  }, [evmAccountAddress, getClaimedAmount]);

  const toast = useToast();
  const whitelist = useMemo(() => {
    return [
      ...whitelist_huhu,
      ...whitelist_iusd,
      ...whitelist_pointer,
      ...whitelist_rat404,
      ...whitelist_voya,
      ...whitelist_misc,
    ];
  }, []);
  const isWhitelisted = useIsWhitelisted(true, whitelist, evmAccountAddress);

  const handleClaim = useCallback(async () => {
    if (!evmAccountAddress || !publicClient) {
      toast({ title: 'Please connect wallet', status: 'info', position: 'top' });
      return;
    }
    if (!isWhitelisted) {
      toast({ title: 'You are not whitelisted', status: 'info', position: 'top' });
      return;
    }
    if (claimedAmount > 0n) {
      toast({ title: 'You have claimed', status: 'info', position: 'top' });
      return;
    }
    const { timestamp: blockTimestamp } = await publicClient.getBlock();
    if (blockTimestamp > endTimestamp) {
      toast({ title: 'Airdrop ended', status: 'info', position: 'top' });
      return;
    }
    if (blockTimestamp < startTimestamp) {
      toast({ title: 'Airdrop has not started yet', status: 'info', position: 'top' });
      return;
    }
    try {
      setLoading(true);
      await claim('drak-airdrop-1');
      toast({ title: 'Claim Successful', status: 'success', position: 'top' });
    } catch (error) {
      console.log(error);
      toast({ title: 'Claim Failed', status: 'error', position: 'top' });
    } finally {
      setLoading(false);
    }
  }, [evmAccountAddress, toast, claim, isWhitelisted, claimedAmount, publicClient]);

  return (
    <Box width="100%" display="flex" flexDir="column" gap="25px" alignItems="center">
      <Image src={tokenLogo} width="45px" />
      <Text fontFamily="PressStart2P" fontSize="20px">
        DRAK Airdrop
      </Text>
      <Box width="100%" padding="10px 20px" bgColor="#1D472C" borderRadius="20px" display="flex">
        <Image src={tokenLogo} width="88px" display={['none', null, 'block']} />
        <Box
          flex={1}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          flexDir={['column', null, 'row']}
          rowGap={'10px'}
        >
          <Text color="#4EB947" display="flex" flexDir="column">
            Start Time
            <Text color="white" fontSize="16px" as="span">
              {new Date(startTimestamp * 1000).toISOString()}
            </Text>
          </Text>
          <Text color="#4EB947" display="flex" flexDir="column">
            End Time
            <Text color="white" fontSize="16px" as="span">
              {new Date(endTimestamp * 1000).toISOString()}
            </Text>
          </Text>
        </Box>
      </Box>
      <Text opacity={0.7}>
        You can claim tokens from the start time to the end time. After the end time, you can no
        longer claim tokens.
      </Text>
      <SimpleGrid width="100%" columns={[1, null, 3]} gap={2}>
        <Box
          width="100%"
          padding="10px"
          display="flex"
          alignItems="center"
          flexDir="column"
          gap="5px"
          border="1px solid #1D472C"
          borderRadius="20px"
          overflow="hidden"
        >
          <Text color="#4EB947" fontSize="16px">
            To claim
          </Text>
          <Text
            display="flex"
            alignItems="center"
            gap="5px"
            color="#3AD673"
            fontFamily="PressStart2P"
            fontSize="15px"
          >
            {isWhitelisted && !claimedAmount ? '1000000' : '-'}
            <Image src={tokenLogo} width="24px" />
          </Text>
        </Box>
        <Box
          width="100%"
          padding="10px"
          display="flex"
          alignItems="center"
          flexDir="column"
          gap="5px"
          border="1px solid #1D472C"
          borderRadius="20px"
          overflow="hidden"
        >
          <Text color="#4EB947" fontSize="16px">
            Claimed
          </Text>
          <Text
            display="flex"
            alignItems="center"
            gap="5px"
            color="#3AD673"
            fontFamily="PressStart2P"
            fontSize="15px"
          >
            {formatNumber(formatEther(claimedAmount))}
            <Image src={tokenLogo} width="24px" />
          </Text>
        </Box>
        <Box
          width="100%"
          padding="10px"
          display="flex"
          alignItems="center"
          flexDir="column"
          gap="5px"
          border="1px solid #1D472C"
          borderRadius="20px"
          overflow="hidden"
        >
          <Text color="#4EB947" fontSize="16px">
            BTC Balance
          </Text>
          <Text
            display="flex"
            alignItems="center"
            gap="5px"
            color="#3AD673"
            fontFamily="PressStart2P"
            fontSize="15px"
          >
            {formatNumber(formatEther(nativeBalance))}
            <Image src={btcLogo} width="24px" />
          </Text>
        </Box>
      </SimpleGrid>
      <CommonButton handleClick={handleClaim} thick loading={loading}>
        Claim
      </CommonButton>
    </Box>
  );
};

export default DrakAirdrop;
