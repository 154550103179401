import { useWallet } from '@solana/wallet-adapter-react';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

const useIsWhitelisted = (
  isEVM: boolean,
  whitelist: string[],
  targetAddress: string | undefined = undefined,
): boolean => {
  const { publicKey: solanaPublicKey } = useWallet();
  const { address: evmAccountAddress } = useAccount();

  return useMemo(() => {
    let addressToUse: string | undefined;
    if (targetAddress) {
      addressToUse = targetAddress;
    } else if (isEVM) {
      addressToUse = evmAccountAddress;
    } else {
      addressToUse = solanaPublicKey?.toString();
    }
    if (!addressToUse) {
      return false;
    }
    return whitelist.some((item) => item.toLowerCase() === addressToUse!.toLowerCase());
  }, [evmAccountAddress, isEVM, solanaPublicKey, targetAddress, whitelist]);
};

export default useIsWhitelisted;
