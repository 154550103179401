import { Box, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';

import styles from './Claim.module.scss';

import { claimList } from '../../const/claim';
import ClaimCard from '../../components/Claim/ClaimCard/ClaimCard';
import _ from 'lodash';

const Claim = () => {
  return (
    <Box
      backgroundColor="#13341F"
      width="100%"
      minHeight="100vh"
      paddingTop="100px"
      fontFamily="fusion"
      position="relative"
    >
      <MainContainer className={styles.container}>
        <SimpleGrid columns={[1, null, 2]} spacing={10} width='100%'>
          {claimList.map((item) => (
            <ClaimCard key={item.title} {..._.omit(item, 'component')} />
          ))}
        </SimpleGrid>
      </MainContainer>
    </Box>
  );
};

export default Claim;
