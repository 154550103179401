import { useNameService } from '../store/name-service-context';

export function useGetNSAddress(key: string | undefined): `0x${string}` | undefined {
  const { singleEntries } = useNameService();

  if (!singleEntries || key === undefined) {
    return undefined;
  }

  return singleEntries[key] as `0x${string}`;
}
