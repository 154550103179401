import { useCallback, useMemo } from 'react';
import { useWeb3Context } from '../store/web3-context';
import { getContract } from 'viem';
import { SingleEntryKeys } from '../store/name-service-context';
import { useGetNSAddress } from './useGetNSAddress';
import AirdropABI from '../abis/AirdropInterface';
import { merlin } from 'viem/chains';
import axios from 'axios';
import { splitSignature } from 'ethers/lib/utils';

export const validDomains = ['drak-airdrop-1'];

const useAirdrop = () => {
  const { evmAccountAddress, publicClient, walletClient } = useWeb3Context();
  const airdropContractAddress = useGetNSAddress(SingleEntryKeys.Airdrop);

  const airdropContract = useMemo(() => {
    if (!publicClient || !airdropContractAddress) return;
    const contract = {
      address: airdropContractAddress,
      abi: AirdropABI,
    };
    if (!walletClient) {
      return getContract({
        ...contract,
        client: publicClient,
      });
    }
    return getContract({
      ...contract,
      client: { public: publicClient, wallet: walletClient },
    });
  }, [publicClient, walletClient, airdropContractAddress]);

  const getClaimedAmount = useCallback(
    async (who: string, entry: string) => {
      if (!airdropContract?.read) {
        return 0n;
      }
      if (!who || !entry) {
        throw new Error('Invalid Parameters');
      }
      const claimed = await airdropContract.read.getClaimedAmount([
        who as `0x${string}`,
        entry as `0x${string}`,
      ]);
      return claimed;
    },
    [airdropContract?.read],
  );

  const claim = useCallback(
    async (domain: string) => {
      if (!evmAccountAddress || !walletClient || !airdropContractAddress || !publicClient) {
        throw new Error('No Wallet');
      }
      if (!validDomains.includes(domain)) {
        throw new Error('Invalid Domain');
      }
      // fetch signature here, throw not found error if not found
      const { data } = await axios.get('https://d15omcey7sm00u.cloudfront.net/api/claimAirdrop', {
        params: {
          address: evmAccountAddress,
          reason: domain,
        },
      });

      if (!data) {
        throw new Error('Signature Not Found');
      }
      const { who, entry, amount, signature } = data;
      const { v, r, s } = splitSignature(signature);
      const hash = await walletClient.writeContract({
        address: airdropContractAddress,
        abi: AirdropABI,
        functionName: 'claim',
        args: [who, entry, amount, v, r as `0x${string}`, s as `0x${string}`],
        chain: merlin,
        account: evmAccountAddress as `0x${string}`,
      });
      // const hash = await publicClient?.simulateContract({
      //   address: airdropContractAddress,
      //   abi: AirdropABI,
      //   functionName: 'claim',
      //   args: [who, entry, amount, v, r as `0x${string}`, s as `0x${string}`],
      //   chain: merlin,
      //   account: evmAccountAddress as `0x${string}`,
      // });

      const receipt = await publicClient.waitForTransactionReceipt({ hash });
      return receipt;
    },
    [evmAccountAddress, airdropContractAddress, walletClient, publicClient],
  );

  return { claim, getClaimedAmount };
};

export default useAirdrop;
