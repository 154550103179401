import { Box, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import useCheckTimePassed from '../../../hooks/useCheckTimePassed';
import { formatNumber } from '../../../utils/utils';
import { CommonButton } from '../../UI/CommonButton';
import { Link } from 'react-router-dom';

type ClaimCardProps = {
  title: string;
  description: string;
  cardBannerImg: string;
  cardTitleLogo: string;
  link: string;
  tokenName: string;
  tokenAmount: string;
  startTimestamp: number;
  endTimestamp: number;
  chain: string;
};

const ClaimCard = ({
  title,
  cardBannerImg,
  cardTitleLogo,
  link,
  tokenName,
  tokenAmount,
  startTimestamp,
  endTimestamp,
  chain,
}: ClaimCardProps) => {
  const isStarted = useCheckTimePassed(startTimestamp * 1000);
  const isEnded = useCheckTimePassed(endTimestamp * 1000);

  const statusTag = useMemo(() => {
    if (!isStarted) {
      return (
        <Box bgColor="black" color="#4EB947" padding="5px 10px" borderRadius="100px">
          Upcoming
        </Box>
      );
    }
    if (isEnded) {
      return (
        <Box bgColor="#0D2B18" color="#57725A" padding="5px 10px" borderRadius="100px">
          Ended
        </Box>
      );
    }
    return (
      <Box
        bgColor="black"
        border="1px solid #226328"
        color="#4EB947"
        padding="5px 10px"
        borderRadius="100px"
      >
        Ongoing
      </Box>
    );
  }, [isEnded, isStarted]);

  const status = useMemo(() => {
    if (!isStarted) {
      return 'Upcoming';
    }
    if (isEnded) {
      return 'Ended';
    }
    return 'Ongoing';
  }, [isEnded, isStarted]);

  return (
    <Box width="100%" borderRadius="20px" overflow="hidden" bgColor='#1D472C'>
      <Box
        width="100%"
        height="180px"
        bgImage={cardBannerImg}
        bgSize="cover"
        bgPosition="bottom center"
        display="flex"
        flexDir="column"
        alignItems="flex-end"
        padding="20px"
      >
        {statusTag}
      </Box>
      <Box width="100%" padding="20px" display="flex" flexDir="column" alignItems='flex-start' gap="20px">
        <Flex alignItems="center" gap="10px" color="white" fontFamily="PressStart2P">
          <Image src={cardTitleLogo} width="48px" />
          {title}
        </Flex>
        <SimpleGrid width="100%" columns={[4]}>
          <Flex flexDir="column" alignItems="center">
            <Text color="#4EB947">Token Name</Text>
            <Text>{tokenName}</Text>
          </Flex>
          <Flex flexDir="column" alignItems="center">
            <Text color="#4EB947">Token Amount</Text>
            <Text>{formatNumber(tokenAmount, '0,0a')}</Text>
          </Flex>
          <Flex flexDir="column" alignItems="center">
            <Text color="#4EB947">Status</Text>
            <Text>{status}</Text>
          </Flex>
          <Flex flexDir="column" alignItems="center">
            <Text color="#4EB947">Blockchain</Text>
            <Text>{chain}</Text>
          </Flex>
        </SimpleGrid>
        <Link to={link}>
          <CommonButton handleClick={() => null} thick>Details</CommonButton>
        </Link>
      </Box>
    </Box>
  );
};

export default ClaimCard;
