import React, { createContext, useEffect, useMemo } from 'react';
import {
  PublicClient,
  WalletClient,
  createPublicClient,
  createWalletClient,
  custom,
  http,
} from 'viem';
import { useAccount, useBalance, useBlockNumber, useWalletClient } from 'wagmi';
import { useETHProvider } from '@particle-network/btc-connectkit';
import { merlin } from 'viem/chains';

export enum WalletType {
  EVM = 'EVM',
  BTC = 'BTC',
}

type Web3ContextType = {
  publicClient: PublicClient | undefined;
  walletClient: WalletClient | undefined;
  evmAccountAddress: string | undefined;
  walletType: WalletType | undefined;
  nativeBalance: bigint;
};

const Web3Context = createContext<Web3ContextType>({
  publicClient: undefined,
  walletClient: undefined,
  evmAccountAddress: undefined,
  walletType: undefined,
  nativeBalance: 0n,
});

export const useWeb3Context = () => {
  const context = React.useContext(Web3Context);
  return context;
};

export const useProvideWeb3Context = () => {
  const { data: blockNumber } = useBlockNumber({ watch: true });
  // evm
  const { address: accountAddressEVM } = useAccount();
  const { data: walletClientEVM } = useWalletClient();

  // btc
  const { evmAccount: evmAccountAddressBTC, provider } = useETHProvider();

  const walletType = useMemo(() => {
    if (accountAddressEVM) {
      return WalletType.EVM;
    // } else if (evmAccountAddressBTC) {
    //   return WalletType.BTC;
    } else {
      return undefined;
    }
  }, [accountAddressEVM]);

  const evmAccountAddress = useMemo(() => {
    if (walletType === WalletType.EVM) {
      return accountAddressEVM;
    } else if (walletType === WalletType.BTC) {
      return evmAccountAddressBTC;
    } else {
      return undefined;
    }
  }, [accountAddressEVM, evmAccountAddressBTC, walletType]);

  const publicClient = useMemo(() => {
    return createPublicClient({ chain: merlin, transport: http() });
  }, []);

  const { data: rawNativeBalance, refetch: refetchNativeBalance } = useBalance({
    address: evmAccountAddress as `0x${string}` | undefined,
  });

  const nativeBalance = useMemo(() => {
    if (rawNativeBalance) {
      return rawNativeBalance.value;
    }
    return 0n;
  }, [rawNativeBalance]);

  useEffect(() => {
    refetchNativeBalance();
  }, [blockNumber, refetchNativeBalance]);

  const walletClient = useMemo(() => {
    if (walletType === WalletType.EVM) {
      return walletClientEVM;
    } else if (walletType === WalletType.BTC) {
      return createWalletClient({
        chain: merlin,
        transport: custom(provider),
        account: evmAccountAddressBTC as `0x${string}` | undefined,
      });
    } else {
      return undefined;
    }
  }, [evmAccountAddressBTC, walletClientEVM, walletType, provider]);

  return { publicClient, evmAccountAddress, walletType, nativeBalance, walletClient };
};

const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const values = useProvideWeb3Context();
  return <Web3Context.Provider value={values}>{children}</Web3Context.Provider>;
};

export default Web3Provider;
