import { Box, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import MainContainer from '../../../components/UI/MainContainer/MainContainer';
import styles from './ClaimDetail.module.scss';
import { FaChevronLeft } from 'react-icons/fa';

const ClaimDetail = () => {
  return (
    <Box
      backgroundColor="#13341F"
      width="100%"
      minHeight="100vh"
      paddingTop="100px"
      fontFamily="fusion"
      position="relative"
    >
      <MainContainer className={styles.container}>
        <Link to="/claim">
          <Text display="flex" alignItems="center" gap="5px" fontSize="16px">
            <Icon as={FaChevronLeft} />
            Back
          </Text>
        </Link>
        <Outlet />
      </MainContainer>
    </Box>
  );
};

export default ClaimDetail;
