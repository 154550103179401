import React from 'react';
import { Flex } from '@chakra-ui/react';
import NavItem from '../NavItem/NavItem';

export type NavItemType = {
  label: string;
  href: string;
  onClick?: () => void;
  isFuture?: boolean;
};

const NAV_CONFIG: NavItemType[] = [
  // { label: 'DRAK IDO', href: '/drakIDO' },
  { label: 'Airdrop', href: '/claim' },
  { label: 'Mining', href: '/mining', isFuture: true },
  { label: 'Metaverse', href: '/metaverse', isFuture: true },
];

type NavMenuProps = {
  onClose?: () => void;
  isMobile?: boolean;
};

const NavMenu = ({ onClose, isMobile }: NavMenuProps) => {
  return (
    <Flex
      gap={isMobile ? '50px' : '25px'}
      flexDir={isMobile ? 'column' : 'row'}
      paddingTop={isMobile ? '50px' : 'none'}
      width={isMobile ? '100%' : 'auto'}
      height={isMobile ? '100%' : 'auto'}
      justifyContent="center"
    >
      {NAV_CONFIG.map((item) => (
        <NavItem key={item.label} {...item} callback={onClose} isMobile={isMobile} />
      ))}
    </Flex>
  );
};

export default NavMenu;
