import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import Home from './pages/Home/Home';

import './index.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import theme from './theme/theme';
import SolanaProvider from './store/solana-context';
// import CandyMachineProvider from './store/candy-machine-context';
// import TokenLaunchNew from './pages/TokenLaunchNew/TokenLaunchNew';
import Claim from './pages/Claim/Claim';
import ClaimDetail from './pages/Claim/ClaimDetail/ClaimDetail';
import { claimList } from './const/claim';
import { Web3ModalProvider } from './store/web3-modal-provider';
import { BTCConnectProvider } from './store/btc-connect-provider';
import Web3Provider from './store/web3-context';
import NameServiceProvider from './store/name-service-context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      // {
      //   path: 'drakIDO',
      //   element: <TokenLaunchNew />,
      // },
      {
        path: 'claim',
        element: <Claim />,
      },
      {
        path: 'claimDetail',
        element: <ClaimDetail />,
        children: claimList.map((item) => ({ path: item.link, element: <item.component /> })),
      },
      { path: '*', element: <Navigate to="" replace /> },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BTCConnectProvider>
        <SolanaProvider>
          <Web3ModalProvider>
            <Web3Provider>
              <NameServiceProvider>
                <RouterProvider router={router} />
              </NameServiceProvider>
            </Web3Provider>
          </Web3ModalProvider>
        </SolanaProvider>
      </BTCConnectProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
