const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'envName',
        type: 'bytes32',
      },
    ],
    name: 'getEnv',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'envName',
        type: 'bytes32',
      },
    ],
    name: 'getEnvSafe',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'keyName',
        type: 'bytes32',
      },
    ],
    name: 'getSingle',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'keyName',
        type: 'bytes32',
      },
    ],
    name: 'getSingleSafe',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'which',
        type: 'address',
      },
    ],
    name: 'isBlocked',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'keyName',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'which',
        type: 'address',
      },
    ],
    name: 'isMultiple',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'keyName',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'which',
        type: 'address',
      },
    ],
    name: 'isMultipleSafe',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'which',
        type: 'address',
      },
    ],
    name: 'isPrivileged',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr',
        type: 'address',
      },
    ],
    name: 'isTrusted',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'listEntries',
    outputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'name',
            type: 'bytes32',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'addr',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'trusted',
                type: 'bool',
              },
            ],
            internalType: 'struct GenericNameServiceType.AddressRecord',
            name: 'record',
            type: 'tuple',
          },
        ],
        internalType: 'struct GenericNameServiceType.SingleEntryRet[]',
        name: 'singleEntries',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'name',
            type: 'bytes32',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'addr',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'trusted',
                type: 'bool',
              },
            ],
            internalType: 'struct GenericNameServiceType.AddressRecord[]',
            name: 'records',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct GenericNameServiceType.MultipleEntryRet[]',
        name: 'multipleEntries',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newAssociatedOperator',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'flag',
        type: 'bool',
      },
    ],
    name: 'setAssociatedOperator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'list',
        type: 'address[]',
      },
      {
        internalType: 'bool',
        name: 'flag',
        type: 'bool',
      },
    ],
    name: 'setBlockList',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'name',
            type: 'bytes32',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'addr',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'trusted',
                type: 'bool',
              },
            ],
            internalType: 'struct GenericNameServiceType.AddressRecord',
            name: 'record',
            type: 'tuple',
          },
          {
            internalType: 'bool',
            name: 'enable',
            type: 'bool',
          },
        ],
        internalType: 'struct GenericNameServiceType.SingleEntryParam[]',
        name: 'singleParams',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'name',
            type: 'bytes32',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'addr',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'trusted',
                type: 'bool',
              },
            ],
            internalType: 'struct GenericNameServiceType.AddressRecord[]',
            name: 'records',
            type: 'tuple[]',
          },
          {
            internalType: 'bool',
            name: 'enable',
            type: 'bool',
          },
        ],
        internalType: 'struct GenericNameServiceType.MultiEntryParam[]',
        name: 'multiParams',
        type: 'tuple[]',
      },
    ],
    name: 'setEntries',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'list',
        type: 'address[]',
      },
      {
        internalType: 'bool',
        name: 'flag',
        type: 'bool',
      },
    ],
    name: 'setPrivilegedList',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

export default abi;
