import {useEffect, useState } from 'react';


const useUpdate = (interval = 1000) => {
  const [timer, setTimer] = useState<number>();

  useEffect(() => {
    let isMounted = true;
    const timerId = setInterval(() => {
      if (isMounted) setTimer(Date.now());
    }, interval);
    return () => {
      isMounted = false;
      clearInterval(timerId);
    };
  }, [interval]);

  return { updateByTimer: timer };
};

export default useUpdate;